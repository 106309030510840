<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden"
      >
        <!--begin: Aside Container-->
        <div
          class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35"
        >
          <!--begin::Logo-->
          <a href="#" class="text-center pt-2">
            <img src="media/logos/logo.png" class="max-h-75px" alt="" />
          </a>
          <!--end::Logo-->
          <!--begin::Aside body-->
          <div class="d-flex flex-column-fluid flex-column flex-center">
            <!--begin::Signin-->
            <div class="login-form login-signin py-11">
              <!--begin::Form-->
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
              >
                <!--begin::Title-->
                <div class="text-center pb-8">
                  <h2
                    class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
                  >
                    Zaloguj się
                  </h2>
                  <span class="text-muted font-weight-bold font-size-h4"
                    >lub
                    <a
                      class="text-primary font-weight-bolder"
                      id="kt_login_signup"
                      @click="showForm('signup')"
                      >Utwórz konto</a
                    ></span
                  >
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark"
                    >Email</label
                  >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label
                      class="font-size-h6 font-weight-bolder text-dark pt-5"
                      >Hasło</label
                    >
                    <a
                      class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      id="kt_login_forgot"
                      @click="showForm('forgot')"
                      >Zapomniałeś hasła?</a
                    >
                  </div>
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Action-->
                <div class="text-center pt-2">
                  <button
                    ref="kt_login_signin_submit"
                    class="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  >
                    Zaloguj się
                  </button>
                </div>
                <!--end::Action-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
            <!--begin::Signup-->
            <div class="login-form login-signup pt-11">
              <!--begin::Form-->
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signup_form"
              >
                <!--begin::Title-->
                <div class="text-center pb-8">
                  <h2
                    class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
                  >
                    Utwórz konto
                  </h2>
                  <p class="text-muted font-weight-bold font-size-h4">
                    Uzupełnij pola, aby utworzyć konto.
                  </p>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Imię i nazwisko"
                    name="fullname"
                    ref="fullname"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    ref="remail"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Hasło"
                    name="password"
                    ref="rpassword"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Powtórz hasło"
                    name="cpassword"
                    ref="cpassword"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                  <label class="checkbox mb-0">
                    <input type="checkbox" name="agree" />
                    Akceptuję&nbsp;<a href="#">regulamin</a>.
                    <span></span>
                  </label>
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div
                  class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3"
                >
                  <button
                    type="button"
                    ref="kt_login_signup_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                  >
                    Utwórz konto
                  </button>
                  <button
                    type="button"
                    id="kt_login_signup_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                    @click="showForm('signin')"
                  >
                    Anuluj
                  </button>
                </div>
                <!--end::Form group-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Signup-->
            <!--begin::Forgot-->
            <div class="login-form login-forgot pt-11">
              <!--begin::Form-->
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
                ref="kt_login_forgot_form"
              >
                <!--begin::Title-->
                <div class="text-center pb-8">
                  <h2
                    class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
                  >
                    Zapomniałeś hasła?
                  </h2>
                  <p class="text-muted font-weight-bold font-size-h4">
                    Podaj swój email aby je zresetować
                  </p>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div
                  class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3"
                >
                  <button
                    type="button"
                    id="kt_login_forgot_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                  >
                    Przypomnij hasło
                  </button>
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                    @click="showForm('signin')"
                  >
                    Anuluj
                  </button>
                </div>
                <!--end::Form group-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Forgot-->
          </div>
          <!--end::Aside body-->
          <!--begin: Aside footer for desktop-->
          <!--          <div class="text-center">-->
          <!--            <button-->
          <!--              type="button"-->
          <!--              class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-h6"-->
          <!--            >-->
          <!--              <span class="svg-icon svg-icon-md">-->
          <!--                &lt;!&ndash;begin::Svg Icon | path:assets/media/svg/social-icons/google.svg&ndash;&gt;-->
          <!--                <svg-->
          <!--                  xmlns="http://www.w3.org/2000/svg"-->
          <!--                  width="20"-->
          <!--                  height="20"-->
          <!--                  viewBox="0 0 20 20"-->
          <!--                  fill="none"-->
          <!--                >-->
          <!--                  <path-->
          <!--                    d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z"-->
          <!--                    fill="#4285F4"-->
          <!--                  />-->
          <!--                  <path-->
          <!--                    d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z"-->
          <!--                    fill="#34A853"-->
          <!--                  />-->
          <!--                  <path-->
          <!--                    d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z"-->
          <!--                    fill="#FBBC05"-->
          <!--                  />-->
          <!--                  <path-->
          <!--                    d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z"-->
          <!--                    fill="#EB4335"-->
          <!--                  />-->
          <!--                </svg>-->
          <!--                &lt;!&ndash;end::Svg Icon&ndash;&gt; </span-->
          <!--              >Sign in with Google-->
          <!--            </button>-->
          <!--          </div>-->
          <!--end: Aside footer for desktop-->
        </div>
        <!--end: Aside Container-->
      </div>
      <!--end::Aside-->
      <!--begin::Content-->
      <div class="content order-1 order-lg-2 d-flex flex-column w-100 pb-0 p-0">
        <!--begin::Title-->
        <div class="h-100">
          <div
            class="content-img d-flex flex-column justify-content-center text-center"
            :style="{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%'
            }"
          ></div>
        </div>
        <!--end::Title-->
        <!--begin::Image-->

        <!--end::Image-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-2.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-2",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-2.jpg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email jest wymagany"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Hasło jest wymagane"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Imię i nazwisko jest wymagane"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email jest wymagany"
            },
            emailAddress: {
              message: "Email jest nieprawidłowy"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Hasło jest wymagane"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Powtórz hasło"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Hasła muszą być takie same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "Zaznacz akceptacje regulaminu"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email jest wymagany"
            },
            emailAddress: {
              message: "Email jest nieprawidłowy."
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: this.errors,
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false
          });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Podaj prawidłowe dane",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    }
  }
};
</script>
